// import { UpdatedPOInformation } from '../components/ReceivingPO/RegularPOList';
import { ProductPermissions } from '../constants/constants';

export interface Error {
  code: string;
  message: string;
}

export interface APIError {
  traceId: string;
  requestId: string;
  errors: Error[];
}

export interface User {
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly employeeId: string;
  readonly route: string;
  readonly permissions: ProductPermissions[];
}

export interface Action<T1 = any, T2 = any> {
  type: T1;
  payload?: T2;
}
export type StyleClasses<T extends string | number | symbol> = Partial<
  Record<T, string>
>;

export interface StoreDetail {
  storeId: string;
  storeNumber: string;
  storeName: string;
  lob: string;
  countryAbb: string;
  stateName: string;
  stateAbb: string;
  city: string;
  zip: string;
  fullZip: string;
  addressLine1: string;
  workPhoneNumber: string;
  latitude: string;
  longitude: string;
}

export interface UpdatedPOInformation {
  purchaseOrderId: string;
  purchaseOrderDetailId: string;
  purchaseOrderDetailIndex?: number;
  purchaseOrderDetailReceivedId?: string;
  poLineItemReceivedId?: string;
  comment?: string;
  reason?: string;
  action?: string;
  manufacturerSerialNumber?: string | null;
  billOfLading?: string | null;
  poType?: string;
}

export interface StoreDetailResponse {
  response: StoreDetail[];
}

export interface StoreConfigsPayload {
  storeNumbers: string[];
  paramKeyNames: string[];
}

export interface StoreConfigDetails {
  paramValue: string;
  paramKeyName: string;
  paramGroupName: string;
  paramCategoryName: string;
  paramKeyId: string;
  paramHierarchy: string;
}

export interface StoreConfigsResponse {
  storeNumber: string;
  configDetails: StoreConfigDetails[];
}

export type ListStatus =
  | 'initial'
  | 'loading'
  | 'apiError'
  | 'empty'
  | 'success';

export interface SelectFieldOption {
  label: string;
  value: string;
}
export interface State {
  readonly name: string;
  readonly abbreviation: string;
  [key: string]: any;
}

interface OrderDateRange {
  endDate: string;
  startDate: string;
}
export interface GetPurchaseOrderRequest {
  storeNumber?: string[];
  coworkerId?: string;
  poDateRange?: OrderDateRange;
  purchaseOrderType?: string[];
  purchaseOrderNumber?: string;
  poStatusType?: string[];
}

export interface ReceivedLineItemDetail {
  poLineItemReceivedId: string;
  receivedComments: string | null;
  poLineItemReceivedBy: string;
  poLineItemReceivedDate: string;
  piecesReceived?: string | number;
  receivedReason?: string;
}
export interface PurchaseOrderReceivedDetail {
  purchaseOrderDetailReceivedId: string;
  inventoryNumber: string;
  manufacturerSerialNumber: string;
  partialReasonId?: string;
  receivedLineItemDetails: ReceivedLineItemDetail[];
  bol?: string;
}
export interface PurchaseOrderDetail {
  purchaseOrderDetailId: string;
  modelNumber: string | null;
  brand: string | null;
  itemCost: number;
  itemDescription: string | null;
  quantityOrdered: number;
  condition: string | null;
  rmsItemNumber: number | string;
  bracket: string;
  subDepartment: string;
  department: string;
  receivedCount: number | null;
  purchaseOrderReceivedDetails: PurchaseOrderReceivedDetail[] | null;
}

export interface PurchaseOrder {
  purchaseOrderId: string;
  purchaseOrderNumber: string;
  externalPoNumber?: string;
  poType: string;
  totalCost: string;
  closeDate: string;
  cancelDate: string;
  createdBy: string;
  createdDate: string;
  storeNumber: string;
  purchaseOrderDetails: PurchaseOrderDetail[];
  vendorName?: string;
  estimatedDeliveryDate?: string;
  poStatusType: string;
}

export interface ReceivePODetail {
  purchaseOrderDetailId: string;
  purchaseOrderDetailReceivedId: string;
  condition: string;
  partial: string;
  partialReceiveReason: string;
  dateReceived: string;
  billOfLading: string;
  manufacturerSerialNumber: string;
  comments: string;
}
export interface ReceivePO {
  purchaseOrderId: string;
  storeNumber: string;
  receivedDetails: ReceivePODetail[];
  poType?: string;
}

export interface ReceivePORequest {
  purchaseOrderReceive: ReceivePO[];
}

export interface PreviouslyReceivedState {
  data: PurchaseOrder[];
  selectedItemId: string | null;
  updatedPO: UpdatedPOInformation[];
}

export interface ClosedCancelledState {
  data: PurchaseOrder[];
  selectedItemId: string | null;
}

export interface SelectedPOToReceiveState {
  data: PurchaseOrder;
  updatedPO: UpdatedPOInformation[];
}

export interface SelectedPORevState {
  data: PurchaseOrder;
  updatedPO: UpdatedPOInformation[];
}

export interface Option {
  label: string;
  value: string;
}

export interface ReversePORequest {
  purchaseOrderReverse: ReversePO;
}

export interface ReversePO {
  purchaseOrderId: string;
  storeNumber?: string;
  receivedDetails: ReversePODetail[];
  poType?: string;
}

export interface ReversePODetail {
  purchaseOrderDetailId: string;
  purchaseOrderDetailReceivedId: string;
}

export interface GetPurchaseOrderResponse {
  purchaseOrders: PurchaseOrder[];
  storeNumbers: string[];
  limit: string;
  offset: string;
}

export interface POSearchFilter {
  readonly poNumber: string;
  readonly fromDate: string;
  readonly toDate: string;
}

export interface ControllerRenderProps {
  onChange: () => void;
  value: string;
  name: string;
}
export interface ReferenceResponse {
  readonly referenceCode: string;
  readonly displaySeq: number;
  readonly defaultValue: string;
  readonly description: string;
  readonly descSpanish?: string;
}

export enum ReferenceKeys {
  PARTIAL_PO_REASON_TYPE = 'PARTIAL_PO_REASON_TYPE',
}
export interface Option {
  label: string;
  value: string;
}

export interface ReceivePOResponse {
  receivedDetails: ReceiveRespPODetail[];
  purchaseOrderId: string;
  purchaseOrderType: string;
  purchaseOrderNumber: string;
}

export interface ReceiveRespPODetail {
  purchaseOrderDetailId: string;
  inventoryNumber: string;
  rmsItemNumber: string;
  receivedStatus: string;
  purchaseOrderDetailReceivedId: string;
  modelNumber: string;
  isSpecialOrderPOCancelled: string;
  manufacturerSerialNumber: string;
}
export interface POQueryParam {
  readonly poNumber: string;
  readonly storeActionItemId: string;
  readonly origin: string;
  readonly customerId: string;
  readonly agreementId: string;
}

export interface StoreActionItemRequest {
  storeActionItemIds: string[];
  storeNumber: string;
}
export interface ManageStoreActionItemsResponse {
  storeActionItemIds: string[];
}
