/* eslint-disable @typescript-eslint/no-unused-vars */
import { Icon, makeStyles, RACButton, RACCard } from '@rentacenter/racstrap';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { useGlobalContext } from '../../context/global/GlobalContext';
import {
  PurchaseOrder,
  ReceivePO,
  ReceivePODetail,
  UpdatedPOInformation,
  ReceivePORequest,
  ReceivePOResponse,
  StoreActionItemRequest,
  ManageStoreActionItemsResponse,
} from '../../types/types';
import { generateFlatReceivedDetails } from './RegularPOList';
import {
  PO_RECEIVED_ERROR,
  PO_RECEIVED_ERROR_TRY_AGAIN,
  PO_RECEIVED_SUCCESS,
  PO_REVERSE_ERROR,
  PO_REVERSED_SUCCESS,
  ReceiveNowType,
  SPO_AGREEMENT_CANCELLED,
} from '../../constants/constants';
import {
  receivePurchaseOrder,
  updateStoreActionItem,
} from '../../api/purchaseOrders';
import { AlertModal } from '../../common/AlertModal/AlertModal';
import { ReverseConfirm } from './ReverseConfirm';
import { TryAgainReceivePOModal } from './TryAgainReceivePOModal';
import { formatDate, getSelectedStore } from '../../utils/utils';
import {
  useSearchActions,
  useSearchDetails,
} from '../../context/Search/SearchProvider';
import { AppRoute } from '../../config/route-config';
import { LoadingOverlay } from '../../common/LoadingOverlay/LoadingOverlay';

export interface ReceivingPOFooter {
  isPrintDisabled: boolean;
  onPrint: () => void;
}
const useStyles = makeStyles((theme: any) => ({
  footerRoot: {
    width: '100%',
    marginBottom: '0rem',
    display: 'block',
    position: 'fixed',
    bottom: '0',
    left: '0',
    zIndex: 1299,
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.typography.pxToRem(0),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0',
  },
  cardBody: {
    flex: '0 0 auto',
    padding: '1rem 1rem',
  },
  leftButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: theme.typography.pxToRem(4),
  },
  rightButtonsContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    gap: theme.typography.pxToRem(4),
  },
  button: {
    height: theme.typography.pxToRem(43),
  },
  hideOnPrint: {
    '@media print': {
      display: 'none',
    },
  },
}));

export const buildReceivePORequest = (
  po: PurchaseOrder,
  updatesOnPO: UpdatedPOInformation[]
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  let poType = '';
  let receivedDetails = [];
  const dateReceived = formatDate(new Date(), 'yyyy-MM-dd');
  if (updatesOnPO.length) {
    receivedDetails = updatesOnPO.map((item) => {
      if (item.poType === 'ER' && !poType) poType = 'ER';
      return {
        purchaseOrderDetailId: item.purchaseOrderDetailId || '',
        purchaseOrderDetailReceivedId: item.purchaseOrderDetailReceivedId || '',
        condition: 'NEW',
        partial: item.action === ReceiveNowType.Partial ? 'true' : 'false',
        partialReceiveReason: item.reason || '',
        dateReceived,
        billOfLading: item.billOfLading || '',
        manufacturerSerialNumber: item.manufacturerSerialNumber || '',
        comments: item.comment || '',
      } as ReceivePODetail;
    });
  } else {
    if (po.poType === 'ER' && !poType) poType = 'ER';
    const flatPODetails = generateFlatReceivedDetails(po.purchaseOrderDetails);
    receivedDetails = flatPODetails
      ?.filter(
        (item) =>
          (item?.quantityOrdered !== item?.receivedCount &&
            !item?.purchaseOrderDetailReceivedId) ||
          (item?.purchaseOrderDetailReceivedId && item?.partialReasonId)
      )
      .map((item) => {
        const isPartialItem = item.partialReasonId;
        const partialReceiveReason = isPartialItem
          ? item.receivedLineItemDetails?.[0].receivedReason || ''
          : '';
        const comments = isPartialItem
          ? item.receivedLineItemDetails?.[0].receivedComments || ''
          : '';
        return {
          purchaseOrderDetailId: item.purchaseOrderDetailId || '',
          purchaseOrderDetailReceivedId:
            item.purchaseOrderDetailReceivedId || '',
          condition: 'NEW',
          partial: isPartialItem ? 'true' : 'false',
          partialReceiveReason,
          dateReceived,
          billOfLading: item.bol || '',
          manufacturerSerialNumber: item.manufacturerSerialNumber || '',
          comments,
        } as ReceivePODetail;
      });
  }

  const receivePO: ReceivePO = {
    purchaseOrderId: po.purchaseOrderId,
    storeNumber: getSelectedStore(),
    receivedDetails,
    poType: poType,
  };

  return receivePO;
};

export const isReceivePODetailsValid = (
  receivePODetails: ReceivePODetail[],
  isRequestForMaualPO: boolean
) => {
  if (!receivePODetails || !receivePODetails.length)
    return {
      isValid: false,
      message: 'Receive purchaseorder details are required.',
    };

  const inValidFields: string[] = [];
  receivePODetails.forEach((item) => {
    if (
      !item.billOfLading &&
      !isRequestForMaualPO &&
      !inValidFields.includes('BOL')
    )
      inValidFields.push('BOL');
    if (!item.manufacturerSerialNumber && !inValidFields.includes('Serial #'))
      inValidFields.push('Serial #');
  });
  if (inValidFields.length) {
    if (inValidFields.length === 1) {
      return {
        isValid: false,
        message: `${inValidFields[0]} is required`,
      };
    } else {
      return {
        isValid: false,
        message: 'Serial # and BOL are required',
      };
    }
  }

  return {
    isValid: true,
    message: '',
  };
};

export const ReceivingPOFooter = ({
  onPrint,
  isPrintDisabled,
}: ReceivingPOFooter) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    selectedPO: { data: selectedPOToReceive, updatedPO },
    // selectedPORev: { data: selectedPORev, updatedPO: revPO }
    previouslyReceived: {
      data: purchaseOrders,
      selectedItemId: selectedPO,
      updatedPO: itemList,
    },
  } = useGlobalContext();

  const { setReloadData } = useSearchActions();
  const { manualPOReceive, queryParamsProp } = useSearchDetails();
  const [isRevDisable, setIsRevDisable] = useState(true);
  const [isReceiveDisable, setIsReceiveDisable] = useState(true);
  const [isReceiving, setIsReceiving] = useState(false);
  const [showTryAgainReceiveModal, setShowTryAgainReceiveModal] =
    useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showConfirmRev, setShowConfirmRev] = useState(false);
  const [message, setMessage] = useState('');
  const [apiHasError, setApiHasError] = useState(false);
  const [hasValidationError, setHasValidationError] = useState(false);
  const [receiveByNumber, setReceiveByNumber] = useState(false);

  useEffect(() => {
    if (selectedPOToReceive?.purchaseOrderId) {
      setIsReceiveDisable(false);
    } else {
      setIsReceiveDisable(true);
    }
  }, [selectedPOToReceive]);

  useEffect(() => {
    if (selectedPO && itemList?.length > 0) {
      setIsRevDisable(false);
    } else {
      setIsRevDisable(true);
    }
  }, [selectedPO, itemList]);

  useEffect(() => {
    const isReceiveByNumber = location?.search.includes('ponumber');
    if (isReceiveByNumber) {
      setReceiveByNumber(isReceiveByNumber);
    }
  }, []);

  const showAlertModal = (message: string) => {
    setShowAlert(true);
    setMessage(message);
  };

  const hideAlertModal = () => {
    setHasValidationError(false);
    setShowAlert(false);
    setMessage('');
  };

  const handleOkClick = () => {
    !apiHasError && !hasValidationError && setReloadData(true);
    hideAlertModal();
    if (queryParamsProp.origin == 'TB') {
      history.push({
        pathname: `/agreement/info/details/${queryParamsProp.customerId}/${queryParamsProp.agreementId}`,
      });
    }
  };

  const handleReceiveError = () => {
    if (manualPOReceive) {
      // If redirected from manual po receive
      setShowTryAgainReceiveModal(true);
    } else {
      showAlertModal(PO_RECEIVED_ERROR);
    }
    setApiHasError(true);
  };

  const handleReceiveClick = () => {
    const receivePOWithPOType = buildReceivePORequest(
      selectedPOToReceive,
      updatedPO
    );
    const { poType, ...receivePO } = receivePOWithPOType;
    const isRequestForMaualPO = poType === 'ER';
    const { isValid, message } = isReceivePODetailsValid(
      receivePO.receivedDetails,
      isRequestForMaualPO
    );
    if (!isValid) {
      setHasValidationError(true);
      showAlertModal(message);
      return;
    }

    const receivePOPayload: ReceivePORequest = {
      purchaseOrderReceive: [receivePO],
    };
    const updateStoreActionItemPayload: StoreActionItemRequest = {
      storeActionItemIds: [queryParamsProp?.storeActionItemId],
      storeNumber: getSelectedStore(),
    };

    setIsReceiveDisable(true);
    setIsReceiving(true);
    setApiHasError(false);
    let alertModal = PO_RECEIVED_SUCCESS;
    receivePurchaseOrder(receivePOPayload)
      .then((response: ReceivePOResponse[]) => {
        if (
          response[0].receivedDetails?.length >= 1 &&
          response[0].receivedDetails.some(
            (detail) => detail.isSpecialOrderPOCancelled === 'true'
          )
        ) {
          alertModal = SPO_AGREEMENT_CANCELLED;
        }
        if (
          queryParamsProp?.origin == 'TB' &&
          queryParamsProp.storeActionItemId
        ) {
          return updateStoreActionItem(updateStoreActionItemPayload);
        }
      })
      .then((res: ManageStoreActionItemsResponse | null) => {
        showAlertModal(alertModal);
      })
      .catch(handleReceiveError)
      .finally(() => {
        setIsReceiveDisable(false);
        setIsReceiving(false);
      });
  };

  const handleRevConfirmClose = () => {
    setShowConfirmRev(false);
    setApiHasError(false);
  };
  const handleRevConfirmFinish = (sucessFlag: boolean) => {
    setShowConfirmRev(false);
    setApiHasError(false);
    if (!sucessFlag) {
      setApiHasError(true);
      showAlertModal(PO_REVERSE_ERROR);
      setReloadData(true);
    } else {
      showAlertModal(PO_REVERSED_SUCCESS);
    }
  };
  return (
    <div className={clsx(classes.footerRoot, classes.hideOnPrint)}>
      {isReceiving && <LoadingOverlay />}
      <RACCard className={classes.card}>
        <div className={clsx(classes.cardBody)}>
          <div className={clsx(classes.row)}>
            <div className={classes.leftButtonsContainer}>
              <AlertModal
                open={showAlert}
                message={message}
                onClose={hideAlertModal}
                onOkClick={handleOkClick}
              />
              <TryAgainReceivePOModal
                open={showTryAgainReceiveModal}
                message={PO_RECEIVED_ERROR_TRY_AGAIN}
                onTryAgain={() => setShowTryAgainReceiveModal(false)}
                onGoBack={() => history.push(AppRoute.ManualPO)}
              />
              <ReverseConfirm
                open={showConfirmRev}
                handleClose={handleRevConfirmClose}
                handleCallFinish={handleRevConfirmFinish}
              />
              {receiveByNumber && (
                <Link
                  style={{ color: 'inherit', textDecoration: 'unset' }}
                  to={{
                    pathname: AppRoute.ManualPO,
                  }}
                >
                  <RACButton variant="outlined" color="secondary">
                    Cancel
                  </RACButton>
                </Link>
              )}
            </div>
            <div className={classes.rightButtonsContainer}>
              <RACButton
                disabled={isReceiveDisable}
                className={clsx(classes.button)}
                variant="contained"
                size="small"
                key="receivingPOFooterPrint"
                color="primary"
                onClick={handleReceiveClick}
              >
                Receive
              </RACButton>
              <RACButton
                disabled={isRevDisable}
                className={clsx(classes.button)}
                variant="contained"
                size="small"
                key="POreverse"
                color="primary"
                onClick={() => {
                  setShowConfirmRev(true);
                  // console.log('ok');
                }}
              >
                Reverse
              </RACButton>
              <RACButton
                disabled={isPrintDisabled}
                className={clsx(classes.button)}
                variant="contained"
                size="small"
                key="receivingPOFooterPrint"
                color="primary"
                startIcon={
                  <Icon>
                    <FontAwesomeIcon icon={faPrint} />
                  </Icon>
                }
                onClick={onPrint}
              >
                Print
              </RACButton>
            </div>
          </div>
        </div>
      </RACCard>
    </div>
  );
};
